import Vue from "vue";
import Breakpoints from "../vue/breakpoints";
import LazyLoad from "vanilla-lazyload";
import Splide from "@splidejs/splide";

import { MatchHeight } from "js-match-height";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let projects = document.querySelector("#projects");
let slider = document.querySelector(".splide");

Vue.config.devtools = true;

// Intiate Vuejs instance
if (projects == null) {
  var app = new Vue({
    el: "#app",
    delimiters: ["${", "}"],
    components: {
      Breakpoints,
    },
  });
}

// Scripts to fire once dom has loaded
document.addEventListener("DOMContentLoaded", () => {
  if (slider != null) {
    new Splide(".splide", {
      perPage: 2,
      autoWidth: true,
      rewind: true,
    }).mount();
  }

  // Open Menu
  const navTrigger = document.querySelector(".js-open-nav");
  const overlay = document.getElementById("overlay");
  const projectWrapper = document.getElementById("app");

  // Toggle Menu
  bindEvent(
    navTrigger,
    "click",
    function () {
      this.classList.toggle("is-active");
      overlay.classList.toggle("show");
      projectWrapper.classList.toggle("js-is-open");
    },
    false,
  );

  // Lazyload
  var lazy = new LazyLoad({
    elements_selector: ".lazy",
  });

  // Accordions
  // If there is an accordion inside an accordion then data-accordion-allow-multiple needs to be used
  const accordionItems = document.querySelectorAll("[data-accordion-item]");
  const accordionContentPanes = document.querySelectorAll(
    "[data-accordion-content]",
  );
  if (accordionItems.length) {
    accordionItems.forEach(function (accordion) {
      const accordionTitleRow = accordion.firstElementChild;
      bindEvent(accordionTitleRow, "click", toggleAccordion);
    });
  }

  function toggleAccordion(e) {
    // This has been added because the a element had nested elements
    var accordionItem = e.target;

    if (e.target.tagName !== "A") {
      accordionItem = e.target.parentElement;
    }
    console.log(accordionItem);

    if (
      accordionItem.parentElement.classList.contains("accordion-item--active")
    ) {
      accordionItem.parentElement.classList.remove("accordion-item--active");
      return;
    }
    accordionContentPanes.forEach(function (content) {
      if (content.previousElementSibling === accordionItem) {
        if (!("accordionAllowMultiple" in content.parentNode.dataset)) {
          content.classList.remove("accordion-item--active");
        }
        content.parentElement.classList.add("accordion-item--active");
      } else {
        if (!("accordionAllowMultiple" in content.parentNode.dataset)) {
          content.parentElement.classList.remove("accordion-item--active");
        }
        // content.classList.add('accordion-item--active');
      }
    });
  }

  function bindEvent(el, eventName, eventHandler) {
    if (el.addEventListener) {
      el.addEventListener(eventName, eventHandler, false);
    } else if (el.attachEvent) {
      el.attachEvent("on" + eventName, eventHandler);
    }
  }

  // File input custom styling
  let fileInputs = document.querySelectorAll(".inputfile");

  if (fileInputs.length != null) {
    Array.prototype.forEach.call(fileInputs, function (input) {
      let label = input.nextElementSibling,
        labelVal = label.innerHTML;

      input.addEventListener("change", function (e) {
        let fileName = "";
        if (this.files && this.files.length > 1) {
          fileName = (this.getAttribute("data-multiple-caption") || "").replace(
            "{count}",
            this.files.length,
          );
        } else {
          fileName = e.target.value.split("\\").pop();
        }
        if (fileName) {
          label.innerHTML = fileName;
        } else {
          label.innerHTML = labelVal;
        }
        console.log(e.target.value);
      });
    });
  }

  let gsapAnim = document.querySelector(".gsapanim");

  if (gsapAnim) {
    // anim 1 //
    let tl = gsap.timeline();

    tl.to("#pathA", { autoAlpha: 1, duration: 0.1, delay: 0.5 });
    tl.to("#pathB", { height: 168, duration: 0.3 });
    tl.to("#pathC", { autoAlpha: 1, duration: 0.1 });
    tl.to("#pathD", { height: 83, duration: 0.15 });
    tl.to("#pathE", { autoAlpha: 1, duration: 0.1 });
    tl.to("#pathF", { height: 229, duration: 0.4 });
    tl.to("#pathG", { autoAlpha: 1, duration: 0.1 });
    tl.to("#pathH", { height: 194, duration: 0.3 });
    tl.to("#pathI", { autoAlpha: 1, duration: 0.1 });
    tl.to("#pathJ", { height: 306, duration: 0.6 });
    tl.to("#pathK", { autoAlpha: 1, duration: 0.1 });
    tl.to("#pathL", { height: 199, duration: 0.3 });
    tl.to("#pathM", { autoAlpha: 1, duration: 0.1 });
    tl.to("#pathN", { height: 378, duration: 0.8 });
    tl.to("#pathO", { autoAlpha: 1, duration: 0.1 });
    tl.to("#pathP", { height: 95, duration: 0.15 });
    tl.to("#pathQ", { autoAlpha: 1, duration: 0.1 });
    tl.to("#pathR", { height: 186, duration: 0.3 });
    tl.to("#pathS", { autoAlpha: 1, duration: 0.1 });
    tl.to("#pathT", { height: 174, duration: 0.3 });

    // anim 2 //
    let tlB = gsap.timeline({
      scrollTrigger: {
        trigger: ".animStart",
        //markers: true,
        start: "top top",
      },
    });

    tlB.to("#pathAb", { height: 72, duration: 0.15 });
    tlB.to("#pathBb", { autoAlpha: 1, duration: 0.1 });
    tlB.to("#pathCb", { height: 186, duration: 0.3 });
    tlB.to("#pathDb", { autoAlpha: 1, duration: 0.1 });
    tlB.to("#pathEb", { height: 129, duration: 0.2 });
    tlB.to("#pathFb", { autoAlpha: 1, duration: 0.1 });
    tlB.to("#pathGb", { height: 204, duration: 0.6 });
    tlB.to("#pathHb", { autoAlpha: 1, duration: 0.1 });
    tlB.to("#pathIb", { height: 169, duration: 0.3 });
    tlB.to("#pathJb", { autoAlpha: 1, duration: 0.1 });
    tlB.to("#pathKb", { height: 111, duration: 0.2 });
    tlB.to("#pathLb", { autoAlpha: 1, duration: 0.1 });

    // anim 2 //
    let tlC = gsap.timeline({
      scrollTrigger: {
        trigger: ".animStartC",
        //markers: true,
        start: "top top",
      },
    });

    tlC.to("#pathAc", { height: 138, duration: 0.2 });
    tlC.to("#pathBc", { autoAlpha: 1, duration: 0.1 });
    tlC.to("#pathCc", { height: 139, duration: 0.3 });
    tlC.to("#pathDc", { autoAlpha: 1, duration: 0.1 });
    tlC.to("#pathEc", { height: 262, duration: 0.3 });
    tlC.to("#pathFc", { autoAlpha: 1, duration: 0.1 });
    tlC.to("#pathGc", { height: 210, duration: 0.5 });
    tlC.to("#pathHc", { autoAlpha: 1, duration: 0.1 });
    tlC.to("#pathIc", { height: 162, duration: 0.3 });
    tlC.to("#pathJc", { autoAlpha: 1, duration: 0.1 });
    tlC.to("#pathKc", { height: 333, duration: 0.6 });
    tlC.to("#pathLc", { autoAlpha: 1, duration: 0.1 });
  }

  // Tabs
  const sectionTabs = document.querySelector(".section-tabs");

  if (sectionTabs) {
    function TabsNavigation() {
      var bindAll = function () {
        var menuElements = document.querySelectorAll("[data-tab]");

        for (var i = 0; i < menuElements.length; i++) {
          menuElements[i].addEventListener("click", change, false);
        }
      };

      var clear = function () {
        var menuElements = document.querySelectorAll("[data-tab]");

        for (var i = 0; i < menuElements.length; i++) {
          menuElements[i].classList.remove("active");
          var id = menuElements[i].getAttribute("data-tab");
          document.getElementById(id).classList.remove("active");
        }
      };

      var change = function (e) {
        clear();
        e.target.classList.add("active");
        var id = e.currentTarget.getAttribute("data-tab");
        document.getElementById(id).classList.add("active");
      };

      bindAll();
    }

    TabsNavigation();
  }
});
